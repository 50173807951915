export const BLOCKQUOTE = 'blockquote';
export const BULLET_LIST = 'bullet_list';
export const HARD_BREAK = 'hardBreak';
export const HEADING = 'heading';
export const LIST_ITEM = 'list_item';
export const ORDERED_LIST = 'ordered_list';
export const PARAGRAPH = 'paragraph';
export const DOC = 'doc';
export const TEXT = 'text';
export const TAB_INDENT = 'tabIndent';
export const LINK = 'link';
